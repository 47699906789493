.title_close_button {
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 6%;
  padding-bottom: 4%;
  align-items: center;
  justify-content: space-between;

  .title {
    color: #5a6363;
    width: 80%;
  }
}
