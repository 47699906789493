.options_icons {
  @apply tw-w-5 tw-h-5;
}

.is_active {
  @apply tw-bg-primary_color tw-flex tw-items-center tw-pl-3 tw-py-3 tw-pr-4 tw-rounded tw-text-gray-50 hover:tw-text-white hover:tw-no-underline;
}

.inactive {
  @apply tw-flex tw-items-center tw-pl-3 tw-py-3 tw-pr-4 tw-rounded tw-text-gray-50 hover:tw-text-white hover:tw-no-underline hover:tw-bg-primary_color_shadow;
}
